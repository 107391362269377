import React from "react";
import withI18n from "i18n/withI18n";
import { PageProps } from "gatsby";

import Teaser from "components/Teaser";
import SEO from "components/seo";

import { Interests, WhitepaperRequstTags } from "constants/mailchimp";

import kentingIcon from "assets/ic_kenting.svg";
import ChipIcon from "assets/ic_chip.inline.svg";
import NISTIcon from "assets/ic_nist.inline.svg";
import ThunderIcon from "assets/ic_thunder.inline.svg";

const description =
  "Accelerated hardware devices for a variety of post-quantum cryptographic primitives. Kenting provides acceleration for NIST compliant post-quantum cryptography, post-quantum zk-SNARKs and eco-friendly mining hardware.";

const KentingTeaser = ({ location }: PageProps) => (
  <>
    <SEO
      title="Kenting"
      description={description}
      pathname={location.pathname}
      titlePrefix
      // locale // ToDo: enable this when i18n is ready
    />
    <Teaser
      title={{ name: "Kenting", iconUrl: kentingIcon }}
      desc={description}
      features={[
        {
          icon: ChipIcon,
          outline: "2x~ Power Efficient",
          desc: <>By using CIM technique, we can save up to 2x of power</>,
        },
        {
          icon: NISTIcon,
          outline: "Post Quantum Secure",
          desc: <>Provides HW acceleration for NIST Compliant post quantum cryptography</>,
        },
        {
          icon: ThunderIcon,
          outline: "Performance Upgrade",
          desc: <>CIM can boost the performance of calculation compared to non-CIM architecture</>,
        },
      ]}
      interests={[Interests.Kenting]}
      whitepaperTag={WhitepaperRequstTags.Kenting}
    />
  </>
);

export default withI18n(KentingTeaser);
